'use strict';

angular.module('enervexSalesappApp').controller('AccountsCtrl', function($scope, $stateParams, Account, Job, AccountRequest,AccountMembershipRequest, _, Auth, Flash,FavoriteAccount, $q, ResidentialCompanyType) {
	$scope.accountId = $stateParams.accountId;
	$scope.allAccounts = [];
	$scope.requests = [];
	$scope.offset = 0;
	$scope.limit = 100;
	$scope.isAdmin = Auth.isAdmin;
	$scope.accessAllAccounts = Auth.accessAllAccounts;
	$scope.selectedAccounts = []
	$scope.accountType = "commercial"
	$scope.status = "active"
	$scope.prefix = ""
	$scope.ctx = {
		httpLoading: false,
		residentialCompanyType: ''
	}

	$scope.residentialCompanyTypes = ResidentialCompanyType.query({})

	AccountRequest.query({
		status:'requested'
	}).$promise.then(function(result){
		$scope.requests = _.compact(_.map(result, function(req) {
			return (req.status != 'approved') ? req : null;
		}))
	})
	AccountMembershipRequest.query({
		status: 'requested'
	}).$promise.then(function(result){
		$scope.membershipRequests = _.compact(_.map(result, function(req) {
			return (req.status != 'approved') ? req : null;
		}))
	})
	function updateFavorites() {
		_.each($scope.allAccounts, function(account){
			var favorite =  _.find($scope.favorites, function(favorite){
				return account._id == favorite.account._id
			})
			account.favorite = (favorite) ? true : false;
		})
	}
	function fetchFavorites() {
		FavoriteAccount.query({}).$promise.then(function(result){
			$scope.favorites = result;
			updateFavorites();
		})
	}
	function fetchAccounts() {
		Account.query({
			offset:$scope.offset,
			limit: $scope.limit,
		}).$promise.then(function(accounts){
			if (accounts.length > 0) {
				$scope.allAccounts = $scope.allAccounts.concat(accounts);
				$scope.offset = $scope.offset + $scope.limit;
				updateFavorites()
				fetchAccounts();
			} else {
				$scope.allAccounts = _.sortBy($scope.allAccounts, function(account){
					return account.name && account.name.toLowerCase()
				})
				updateFavorites()
				$scope.selectFilter();
				$scope.ctx.httpLoading = false
			}
		});
	}
	function refetchAccounts() {
		$scope.allAccounts = [];
		$scope.offset = 0;
		fetchAccounts();
	}
	fetchAccounts();
	fetchFavorites();
	$scope.favorite = function(account){
		FavoriteAccount.save({
		}, {
			account: account._id
		}).$promise.then(function(res){
			account.favorite = true;
			fetchFavorites();
		});
	}
	$scope.unfavorite = function(account){
		var favorite = _.find($scope.favorites, function(favorite){
			return favorite.account._id == account._id;
		});
		FavoriteAccount.remove({
			id: favorite.account._id
		}).$promise.then(function(res){
			account.favorite = false;
			fetchFavorites();
		});
	}
	$scope.getSelected = function() {
		$scope.selectedAccounts = _.filter($scope.accounts, function(account){
			return account.selected;
		})
	}
	function doesntContainResidentialCompanyType(account, residentialCompanyType) {
		if (account.accountType == "residential" && residentialCompanyType && residentialCompanyType != '') {
			var found = _.find(account.residentialCompanyTypes, function(rct){
				return rct == residentialCompanyType
			})
			if (!found) {
				return true
			}
		}
		return false
	}
	$scope.selectFilter = function() {
		var regex = null;
		if ($scope.accountNameFilter && $scope.accountNameFilter != ""){
			regex = new RegExp($scope.accountNameFilter, "i")
		}
		$scope.accounts = _.filter($scope.allAccounts, function(account){
			if ($scope.accountType && $scope.accountType != "" && $scope.accountType != account.accountType) {
				return false
			} else if ($scope.status && $scope.status !="" && $scope.status != account.status) {
				return false
			} else if ($scope.prefix =="set" && !(account.prefixCode && account.prefixCode != "")) {
				return false
			} else if ($scope.prefix =="not-set" && (account.prefixCode && account.prefixCode != "")) {
				return false
			} else if ($scope.prefix =="not-set" && (account.prefixCode && account.prefixCode != "")) {
				return false
			} else if (regex && !(regex.test(account.name) || regex.test(account.prefixCode))) {
				return false
			} else if (doesntContainResidentialCompanyType(account, $scope.ctx.residentialCompanyType)) {
				return false
			} else {
				return true
			}
		})
	}
	$scope.selectAll = function() {
		_.each($scope.accounts, function(account){
			account.selected = true
		})
		$scope.getSelected()
	}
	$scope.selectNone = function() {
		_.each($scope.accounts, function(account){
			account.selected = false
		})
		$scope.getSelected()
	}
	$scope.archiveAccounts = function() {
		$scope.ctx.httpLoading = true
		var accounts = _.filter($scope.accounts, function(account){
			return account.selected;
		})
		$q.all(_.map(accounts, function(account){
			var payload = _.clone(account)
			payload.status = "archived"
			return Account.update({
				id: account._id
			}, payload).$promise
		})).then(function(r){
			var message = '<strong>Success!</strong> You successfully archived ' + accounts.length + ' accounts.';
			Flash.create('success', message, 0, {}, true);
			refetchAccounts();
		}).catch(function(r){
			$scope.ctx.httpLoading = false
		})
	}
	$scope.mergeAccounts = function() {
		$scope.ctx.httpLoading = true
		var accounts = _.filter($scope.accounts, function(account){
			return account.selected;
		})
		var account = accounts.pop();
		var accountIds = _.map(accounts, function(account){
			return account._id
		})
		var accountId = account._id;
		Account.merge({
			id: accountId
		}, {
			accountIds: accountIds
		}).$promise.then(function(result){
			
			$scope.success = true;
			var url = "/accounts/"+accountId;
			var message = '<strong>Success!</strong> You successfully merged into ' + account.name + ', <a href="'+url+ '">check it out</a> .';
			Flash.create('success', message, 0, {}, true);
			$scope.ctx.httpLoading = true
			refetchAccounts();
		}).catch(function(err){
			$scope.ctx.httpLoading = false
			
			$scope.errors = {};
			$scope.errors.other = err.message;
			var message = err.message || "There as an error syncing the fancalc job";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		})
		console.log(JSON.stringify(accounts))
	}
	$scope.showAccounts = true;
	$scope.getJobs = function(account) {
		Job.query({
			accountId: account._id
		}).$promise.then(function(jobs){
			$scope.jobs = jobs
		})
	}

});
